<template>
	<b-modal id="edit-asset-type" size="lg" title="Edit Asset Type" ref="modal" ok-title="Update" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true" hide-header-close>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs>
						<b-tab title="Primary Information" active>
							<b-row class="my-2">
								<b-col sm="8">
									<b>PRIMARY INFORMATION</b>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="8" md="12" sm="12">
									<b-form-group label="Origin" label-for="origin"
										description="Only the companies with Asset Owner or Hybrid type are listed here.">
										<b-form-input name="Origin" type="text" v-model="form.origin" maxlength="25"
											readonly />
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Asset Code Tagging" label-for="assetTagging" description="">
										<b>{{ form.assetTagging }}</b>
									</b-form-group>
								</b-col>

								<b-col lg="6" md="6" sm="12" v-if="form.assetTagging === 'Default'">
									<b-form-group label="Category" label-for="Category">
										<b-form-input name="Category" type="text" v-model="form.type" maxlength="25"
											readonly />
									</b-form-group>
								</b-col>
								<b-col v-else lg="6" md="12" sm="12">
									<b-form-group label="Has Categories?">
										<b-form-radio-group name="Has Categories" v-model="form.hasCategories"
											v-validate="'required'" :options="yesNoOptions" />
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Name" label-for="name" :description="isDefault
										? 'This is a predetermined value based on the selected category and origin'
										: 'Allowed characters are numbers, letters, and some special characters: :, -, (, ), \\, /, and ,'
										">
										<b-form-textarea name="Name" type="text" v-model="form.name" maxlength="100"
											:rows="1" readonly />
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Description" label-for="description" description>
										<b-form-textarea name="Description" type="text" v-model="form.description"
											maxlength="200" :rows="4" placeholder="Description" v-validate="{
												required: true,
												regex: remarksRegex
											}" />
										<span v-show="errors.has('Description')" class="help-block">{{
											errors.first('Description')
										}}</span>
									</b-form-group>
								</b-col>

								<b-col sm="6">
									<b-form-group label="Number of Stickers per Asset" label-for="description" description>
										<b-form-input name="Number of Stickers" type="number" class="numFont"
											v-model="form.noOfStickers" min="1" max="10"
											v-validate="'required|numeric|min_value:1|max_value:10'" />
										<span v-show="errors.has('Number of Stickers')" class="help-block">{{
											errors.first('Number of Stickers')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Inventory Details">
							<b-row class="my-2">
								<b-col sm="8">
									<b>INVENTORY DETAILS</b>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="6" md="6" sm="6">
									<b-form-group label="Unit of Measure (UOM)" label-for="uom">
										<v-select name="Unit of Measure" class="style-chooser" label="text" placeholder=" - Please select - "
											:options="uomOptions" :reduce="(uom) => uom.value" v-model="form.uom"
											v-validate="{
												required: true,
											}">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a uom
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Unit of Measure')" class="help-block">{{
											errors.first('Unit of Measure')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="6" md="6" sm="6">
									<b-form-group label="Unit Cost (PHP)" label-for="unit cost" description>
										<b-form-input name="Unit Cost" type="number" class="numFont" step="0.01"
											v-model="form.unitCost" v-validate="'required|decimal'" />
										<span v-show="errors.has('Unit Cost')" class="help-block">{{
											errors.first('Unit Cost')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Conditions">
							<AssetTypeConditions :form="form" />
						</b-tab>

						<b-tab title="Asset Tagging">
							<div v-show="form.assetTagging === 'Default'">
								<AssetTypeCodes :form="form" />
							</div>

							<div v-show="form.assetTagging === 'Custom'">
								<b-row class="my-2">
									<b-col sm="8">
										<b>DEFINE ASSET TAGGING</b>
									</b-col>
								</b-row>

								<b-row class="mb-1 my-3" no-gutters>
									<b-col sm="12" class="my-1">
										<b-form-group label="Label Tag Format" label-for="Regular Expression"
											description="This should be in regular expression form that covers all tagging specifications of your asset code">
											<b-form-textarea name="Regular Expression" type="text" v-model="form.regex"
												v-validate="{
													required: !isDefault,
												}" maxlength="500" placeholder="Enter label tag format here..." rows="3" max-rows="6" />
											<span v-show="errors.has('Regular Expression')" class="help-block">
												{{ errors.first('Regular Expression') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="my-2">
									<b-col sm="8"> Test Values </b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="6" class="mr-4">
										<b-form-group :description="'Test Value 1'">
											<b-form-input :name="'Test Value 1'" type="text" v-model="form.testValues[0]"
												v-validate="{
													required: !isDefault,
													regex: regex,
												}" placeholder="Type Here" maxlength="50" />
											<span v-show="errors.has('Test Value 1')" class="help-block">
												{{ errors.first('Test Value 1') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col sm="2" class="mt-1" v-if="regex && regex.test(form.testValues[0])">
										<span class="lbl-passed">PASSED</span>
									</b-col>
									<b-col sm="2" class="mt-1" v-else>
										<span class="lbl-failed">FAILED</span>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="6" class="mr-4">
										<b-form-group :description="'Test Value 2'">
											<b-form-input :name="'Test Value 2'" type="text" v-model="form.testValues[1]"
												v-validate="{
													required: !isDefault,
													regex: regex,
												}" placeholder="Type Here" maxlength="50" />
											<span v-show="errors.has('Test Value 2')" class="help-block">
												{{ errors.first('Test Value 2') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col sm="2" class="mt-1" v-if="regex && regex.test(form.testValues[1])">
										<span class="lbl-passed">PASSED</span>
									</b-col>
									<b-col sm="2" class="mt-1" v-else>
										<span class="lbl-failed">FAILED</span>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="6" class="mr-4">
										<b-form-group :description="'Test Value 3'">
											<b-form-input :name="'Test Value 3'" type="text" v-model="form.testValues[2]"
												v-validate="{
													required: !isDefault,
													regex: regex,
												}" placeholder="Type Here" maxlength="50" />
											<span v-show="errors.has('Test Value 3')" class="help-block">
												{{ errors.first('Test Value 3') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col sm="2" class="mt-1" v-if="regex && regex.test(form.testValues[2])">
										<span class="lbl-passed">PASSED</span>
									</b-col>
									<b-col sm="2" class="mt-1" v-else>
										<span class="lbl-failed">FAILED</span>
									</b-col>
								</b-row>
							</div>
						</b-tab>

						<b-tab title="Categories" v-if="form.hasCategories">
							<AssetTypeCategories :form="form" :allAssetTypeCategories="allAssetTypeCategories" />
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Components
import AssetTypeConditions from './AssetTypeConditions';
import AssetTypeCodes from './AssetTypeCodes';
import AssetTypeCategories from './AssetTypeCategories';

// Util
import { AssetTypeUtil } from '@/utils/assetTypeUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API & Database
import api from '@/api/assetTypeApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'edit-asset-type',
	components: {
		AssetTypeConditions,
		AssetTypeCodes,
		AssetTypeCategories,
		Loading,
	},
	props: {
		uomOptions: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			form: {
				id: '',
				name: '',
				origin: '',
				originId: '',
				brand: '',
				type: null,
				description: '',
				noOfStickers: 1,
				unitCost: 0,
				uom: '',
				isActive: 'true',
				conditions: AssetTypeUtil.getDefaultConditions(),
				assetTypeCodes: [],
				assetTagging: 'Default',
				regex: '',
				testValues: ['', '', ''],
				hasCategories: false,
				categories: [],

				dateCreated: '',
				createdBy: '',
				dateUpdated: '',
				updatedBy: '',
			},

			yesNoOptions: [
				{ text: 'YES', value: true },
				{ text: 'NO', value: false },
			],

			allAssetTypeCategories: {},
			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		isDefault() {
			return this.form.assetTagging === 'Default';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.name;
		},
		regex() {
			try {
				return new RegExp(this.form.regex);
			} catch (_error) {
				return null;
			}
		},
		remarksRegex() {
			return config.remarksRegex;
		}
	},
	mounted() {
		setTimeout(() => {
			this.allAssetTypeCategories = { ...this.$store.getters.assetTypeCategories };
			this.categoryOptions = DropDownItemsUtil.retrieveAssetCategories(this.allAssetTypeCategories, false);
		}, config.timeout);
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			for (const condition of this.form.conditions) {
				if (!condition.description || !/^([A-Za-z ])*$/.test(condition.description)) {
					this.$toaster.warning(`Condition '${condition.condition}' has invalid description. It must contain only letters (A-Z, a-z).`);
					this.isLoading = false;
					return;
				} else if (condition.description.length > 25) {
					this.$toaster.warning(`Invalid condition description. It must be at most 25 characters long.`);
					this.isLoading = false;
					return;
				}
			}

			if (this.form.assetTagging === 'Default' && this.form.assetTypeCodes.length === 0) {
				this.$toaster.warning('At least 1 asset type code is required.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (this.form.assetTagging === 'Custom') {
				let assetTypeName = this.form.name.trim();
				if (!assetTypeName.startsWith(this.selCompany.brand)) {
					this.$toaster.warning(`Asset type name must start with the origin's brand name: ${this.selCompany.brand}`);
					// hide loading indicator
					this.isLoading = false;
					return;
				} else if (assetTypeName === this.selCompany.brand) {
					this.$toaster.warning('Please complete your asset type name');
					// hide loading indicator
					this.isLoading = false;
					return;
				}

				try {
					new RegExp(this.form.regex);
				} catch (_error) {
					this.$toaster.warning('Invalid label tag format.');
					// hide loading indicator
					this.isLoading = false;
					return;
				}

				let testValues = this.form.testValues;
				if (
					!this.regex.test(testValues[0]) &&
					!this.regex.test(testValues[1]) &&
					!this.regex.test(testValues[2])
				) {
					this.$toaster.warning('All test values must pass the label tag format.');
					// hide loading indicator
					this.isLoading = false;
					return;
				}

				let uniqCount = _.uniq(this.form.testValues).length;
				if (uniqCount < 3) {
					this.$toaster.warning('All test values must be unique.');
					// hide loading indicator
					this.isLoading = false;
					return;
				}

				if (this.form.hasCategories && this.form.categories.length < 2) {
					this.$toaster.warning('At least 2 categories is required.');
					// hide loading indicator
					this.isLoading = false;
					return;
				}
			}

			await this.handleSubmit();
		},

		getParam() {
			// Removes excess whitespace
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);

			if (this.form.assetTagging === 'Custom') {
				let brand = this.selCompany.brand;
				let assetTypeName = this.form.name;
				this.form.type = assetTypeName.substring(brand.length).trim();
			}

			this.form.noOfStickers = parseInt(this.form.noOfStickers);
			this.form.unitCost = parseFloat(this.form.unitCost);

			return {
				currUserId: this.loggedUser.id,
				assetType: this.form,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let param = this.getParam();
				let { data } = await api.saveAssetType(param);

				if (data.isSuccess) {
					this.$toaster.success(`Asset Type "${this.name}" was updated successfully.`);
					EventBus.$emit('onCloseSaveAssetType', {
						assetType: data.assetType,
						assetTypeCodes: data.assetTypeCodes,
					});
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error updating asset type "${this.name}". Please try again.`);
				}

			} catch (_error) {
				this.$toaster.error(`Error updating asset type "${this.name}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		async onReset() {
			// show loading indicator
			this.isLoading = true;

			let assetTypeId = this.$store.getters.currAssetType.id;
			let param = {
				currUserId: this.loggedUser.id,
				assetTypeId: assetTypeId,
			};
			const { data } = await api.getAssetType(param);
			let assetType = data.assetType;
			assetType.assetTypeCodes = data.assetTypeCodes;

			if (!assetType.hasOwnProperty('categories')) {
    			assetType.categories = [];
			}

			let assetTypeJSON = JSON.stringify(assetType);
			this.form = JSON.parse(assetTypeJSON);

			if (!this.form.conditions) {
				this.$set(this.form, 'conditions', AssetTypeUtil.getDefaultConditions());
			}

			// update other fields
			this.form.noOfStickers = typeof assetType.noOfStickers === 'undefined' ? 1 : assetType.noOfStickers;
			this.form.unitCost = typeof assetType.unitCost === 'undefined' ? 1 : assetType.unitCost;
			this.form.uom = assetType.uom ? assetType.uom : '';
			this.form.assetTagging = assetType.assetTagging ? assetType.assetTagging : 'Default';

			if (this.form.assetTagging === 'Custom') {
				this.form.regex = assetType.regex ? assetType.regex : '';
				this.form.testValues = assetType.testValues ? assetType.testValues : [];
			}

			// add default values for categories-related fields
			if (typeof assetType.hasCategories === undefined || this.form.hasCategories === null) {
				this.form.hasCategories = false;
				this.form.categories = [];
			}

			// init company
			this.selCompany = {
				id: assetType.originId,
				name: assetType.origin,
				brand: assetType.brand,
			};

			// reset validation
			this.$validator.reset();
			this.errors.clear();

			// hide loading indicator
			this.isLoading = false;
		},
	},
};
</script>

<style scoped>
.lbl-passed {
	font-weight: bold !important;
	color: #28a745 !important;
}

.lbl-failed {
	font-weight: bold !important;
	color: #dc3545 !important;
}
</style>

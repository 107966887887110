<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">ASSET TYPE CATEGORIES</span>
                <div class="details-view-subtitle">List of subcategories per asset type</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="categories" :fields="categoryFields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive />

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'asset-type-categories-details-view',
    props: {
        row: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            categories: [],
            categoryFields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: 'Category'
                },
            ],

            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    mounted() {
        // retrieve conditions
        if (this.row.item.hasCategories) {
            this.categories = this.getCategories(this.row.item);
            this.totalRows = _.size(this.categories);
        }
    },
    methods: {
        getCategories(assetType) {
            let categories = [];

            _.forEach(assetType.categories, (item, key) => {
                let index = key + 1;
                categories.push({
                    index: index,
                    name: item.name
                });
            });

            return categories;
        },
    }
}

</script>
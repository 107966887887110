<template>
	<span>
		<span v-if="assetType.isActive === 'false'">
			<b-badge variant="secondary">Inactive</b-badge>
		</span>
		<span v-else>
			<b-badge variant="success">Active</b-badge>
		</span>
	</span>
</template>

<script>
export default {
	name: 'asset-type-status',
	props: {
		assetType: {
			type: Object,
			required: true
		}
	}
};
</script>

<style></style>
import { db } from '@/config/firebase';

async function getAssetTypeCodes() {
    let assetTypeCodesObj = {};

    const dbRef = db.collection('assetTypeCodes');
    const querySnapshots = await dbRef.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let assetTypeCodeObj = doc.data();
        assetTypeCodesObj[id] = {
            id: id,
            ...assetTypeCodeObj
        }
    });

    return assetTypeCodesObj;
}

async function getAssetTypeCodeByPrefix(prefix) {
    let assetTypeCodeObj = {};
    const query = db.collection('assetTypeCodes').where("prefix", "==", prefix).limit(1);
    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
        assetTypeCodeObj = doc.data();
    });

    return assetTypeCodeObj;
}

export default {
    getAssetTypeCodes,
    getAssetTypeCodeByPrefix
}